<script setup lang="ts">
const user = useSupabaseUser();
const { auth } = useSupabaseClient();

const links = [
  {
    label: "Ménages",
    to: "/c",
    icon: "i-material-symbols-nest-eco-leaf-rounded",
  },
  {
    label: "Logements",
    to: "/c/properties",
    icon: "i-heroicons-home-solid",
  },
  {
    label: "Prestataires",
    to: "/c/workers",
    icon: "i-heroicons-user-group-solid",
  },
];

const logout = async () => {
  await auth.signOut();
  await navigateTo("/login");
};
</script>

<template>
  <section class="flex flex-col border-r-[1px]">
    <img src="/logo.svg" class="logo m-4 self-center" alt="Sweep Logo" />
    <UVerticalNavigation  :links="links" class="m-4 grow" />
    <div class="flex items-center justify-between border-t-[1px] px-4 py-2">
      <p class="text-sm font-medium text-gray-500">{{ user?.email }}</p>
      <UTooltip text="Déconnexion">
        <UButton
        icon="i-jam-log-out"
        color="gray"
        variant="link"
        :padded="false"
        size="xs"
        @click="logout"
        />
      </UTooltip>
    </div>
  </section>
</template>

<style scoped>
.logo {
  height: 21.53px;
  width: 125px;
}
</style>
