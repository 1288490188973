<script setup></script>

<template>
  <div class="flex overflow-hidden h-screen">
    <ConciergeUiMenu class="w-1/5" />
    <main class="overflow-auto w-4/5">
      <slot />
    </main>
    <UModals />
  </div>
</template>

<style scoped>
*,
html {
  font-family: "Open Sans", sans-serif;
}
</style>
